<template>
  <div class="bg-gray-800">
    <div class="flex flex-wrap">
      <div class="w-full">
        <BlockGroup
          :label="'Options'"
        >
          <InputWrapper
            type="toggle"
            :label="'Visible'"
            :value="node.visible"
            @change="onChangeVisible"
          />

          <InputWrapper
            type="button"
            :simple="true"
            :value="'Delete'"
            @change="onDelete"
          />
        </BlockGroup>
      </div>
      <!-- </div> -->
    </div>
  </div>
</template>

<script>
import mixin from './NodeSettingMixin'

export default {
  name: 'ObjectBlock',

  mixins: [mixin],

  data () {
    return {
      collapsed: false
    }
  },

  methods: {

    onChangeVisible () {
      this.gui.updateNode(() => {
        this.node.visible = !this.node.visible
      })
    },

    onDelete () {
      if (window.confirm('Are you sure you want to delete this node?')) {
        this.gui.deleteObjectById(this.node.id)
      }
    }
  }
}
</script>
